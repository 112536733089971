<template>
  <div class="search-privince-info">
    <BannerSearchPrivinceInfo></BannerSearchPrivinceInfo>
  </div>
</template>

<script>
  import BannerSearchPrivinceInfo from '@/components/banner/BannerSearchPrivinceInfo.vue'
  export default {
    name: 'SearchPrivinceInfo',
    components: {
      BannerSearchPrivinceInfo
    },
    // created() {
    //   console.log('--------------||----')
    // },
    // beforeRouteEnter(to, from, next) {

    //   console.log('test-----1---')
    //   next()
    // }
  }
</script>

<style>
</style>

<template>
  <div class="banner-container banner-search-privince-info">
    <img
      class="banner-bg"
      src="@/assets/images/banner/banner-search-privince-info-bg.png"
      alt="banner"
    />
    <div class="container-of">
      <div class="logo-wrapper">
        <img
          @click="goHome()"
          class="logo"
          src="@/assets/images/logo.png"
          alt="logo"
        />
      </div>
      <h1>{{ privinceName }}教育招生考试院</h1>
      <div class="btn-wrapper">
        <button type="button" class="btn" @click="goSearchInfo()">
          考试信息查询
        </button>
      </div>
      <div class="btn-wrapper">
        <button type="button" class="btn" @click="goPerformanceInfo()">
          成绩查询入口
        </button>
      </div>
      <div class="btn-wrapper">
        <button type="button" class="btn" @click="goCourseMaterials()">
          课程教材
        </button>
      </div>
      <div class="btn-wrapper">
        <button type="button" class="btn" @click="goExaminationSchedule()">
          考试日程
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerSearchPrivinceInfo',
  data() {
    return {
      privinceName: '江苏省',
    }
  },
  // computed: {
  // privinceName() {
  //   if (this.$store.state.privinceInfo) {
  //     return this.$store.state.privinceInfo.provinceName;
  //   } else {
  //     return JSON.parse(localStorage.getItem('privinceInfo')).provinceName
  //   }
  // }
  // },
  created() {
    this.init()
  },
  // beforeRouteEnter(to, from, next) {
  //   next()
  //   console.log('-------------oooo----------------')
  // },
  methods: {
    init() {
      // console.log(localStorage.getItem('privinceInfo'))
      let timer = setTimeout(() => {
        console.log('this.$store.state.provinceInfo')
        console.log(this.$store.state.provinceInfo)
        console.log("localStorage.getItem('provinceInfo')")
        console.log(localStorage.getItem('provinceInfo'))
        if (this.$store.state.provinceInfo) {
          this.privinceName = this.$store.state.provinceInfo.provinceName
        } else {
          this.privinceName = JSON.parse(
            localStorage.getItem('provinceInfo'),
          ).provinceName
        }
        clearTimeout(timer)
      }, 200)
    },
    goHome() {
      // window.location.href = "/";
      this.$router.push({
        path: '/',
      })
    },
    goSearchInfo() {
      let url
      if (this.$store.state.provinceInfo) {
        // location.href = this.$store.state.privinceInfo.examinfoLink
        url = this.$store.state.provinceInfo.examinfoLink
        window.open(url, '_blank')
      } else {
        url = JSON.parse(localStorage.getItem('provinceInfo')).examinfoLink
        // location.href = JSON.parse(localStorage.getItem('privinceInfo')).examinfoLink
        window.open(url, '_blank')
      }
    },
    goPerformanceInfo() {
      let url
      if (this.$store.state.provinceInfo) {
        // location.href = this.$store.state.privinceInfo.performanceLink
        url = this.$store.state.provinceInfo.performanceLink
        window.open(url, '_blank')
      } else {
        // location.href = JSON.parse(localStorage.getItem('privinceInfo')).performanceLink
        url = JSON.parse(localStorage.getItem('provinceInfo')).performanceLink
        window.open(url, '_blank')
      }
    },
    goCourseMaterials() {
      let url
      if (this.$store.state.provinceInfo) {
        // location.href = this.$store.state.privinceInfo.performanceLink
        url = this.$store.state.provinceInfo.textBook
        window.open(url, '_blank')
      } else {
        // location.href = JSON.parse(localStorage.getItem('privinceInfo')).textBook
        url = JSON.parse(localStorage.getItem('provinceInfo')).textBook
        window.open(url, '_blank')
      }
    },
    goExaminationSchedule() {
      let url
      if (this.$store.state.provinceInfo) {
        // location.href = this.$store.state.privinceInfo.performanceLink
        url = this.$store.state.provinceInfo.schedule
        window.open(url, '_blank')
      } else {
        // location.href = JSON.parse(localStorage.getItem('privinceInfo')).schedule
        url = JSON.parse(localStorage.getItem('provinceInfo')).schedule
        window.open(url, '_blank')
      }
    },
  },
}
</script>

<style lang="scss">
.banner-search-privince-info {
  text-align: center;
  // min-height: 750px;
  min-height: 900px;
  .logo-wrapper {
    padding-top: 50px;
    padding-left: 10px;
    margin: auto;
    width: 1140px;
    text-align: left;
    .logo {
      height: 50px;
      width: 150px;
      min-height: initial;
      cursor: pointer;
    }
  }
  img.banner-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    object-fit: cover;
  }

  .container-of {
    // height: 100%;
    text-align: center;

    h1 {
      padding: 2% 0 2%;
      font-size: 50px;
      color: #fff;
    }

    .btn-wrapper {
      margin-top: 5%;
    }

    .btn {
      // display: block;
      height: initial;
      font-size: 28px;
      border-radius: 8px;
      width: 80%;
      padding: 2% 0;
      color: #fff;

      &:hover {
        background-color: rgba(255, 255, 255, 0.35);
      }
    }
  }
  @media (max-width: 991.98px) {
    .container-of {
      top: 50%;
    }
  }
}

@media (max-width: 767.98px) {
  .banner-search-privince-info {
    min-height: 600px;

    .container-of {
      h1 {
        padding-top: 10%;
        font-size: 28px;
      }

      .btn {
        font-size: 20px;
      }
    }
  }
}
</style>
